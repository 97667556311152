const sessionHistoryEntries: Array<{url: string; state: unknown}> = []
let sessionHistoryOffset = 0
let state: State

type State = {
  _id: number
  turbo?: {
    restorationIdentifier: string
  }
}

/*
 * Array of history entries.
 *
 * Example:
 * {
 *   0: {
 *     url: "/",
 *     state: { ... }.
 *   },
 *   1: {
 *     url: "/github/github/issues/123",
 *     state: { ... }.
 *   },
 *   2: {
 *     url: "/github/github/issues/123#comment-4",
 *     state: { ... }.
 *   }.
 *  }.
 */

export function getState(): State {
  return state
}

function safeGetHistory() {
  try {
    // Clamp history.length to 0<->9007199254740991 (Number.MAX_SAFE_INTEGER isn't supported in IE)
    return Math.min(Math.max(0, history.length) || 0, 9007199254740991)
  } catch (e) {
    return 0
  }
}

function initializeState(): State {
  const newState = {_id: new Date().getTime(), ...history.state}
  setState(newState)
  return newState
}

// Current index into history entries stack.
function position(): number {
  return safeGetHistory() - 1 + sessionHistoryOffset
}

function setState(newState: State) {
  state = newState

  // Update entry at current position
  const url = location.href
  sessionHistoryEntries[position()] = {url, state}

  // Trim entries to match history size
  sessionHistoryEntries.length = safeGetHistory()

  // Emit public statechange
  window.dispatchEvent(new CustomEvent('statechange', {bubbles: false, cancelable: false}))
}

// Generate unique id for state object.
//
// Use a timestamp instead of a counter since ids should still be unique
// across page loads.
function uniqueId(): number {
  return new Date().getTime()
}

// Indirection for history.pushState to support tracking URL changes.
//
// Would be great if there was a standard window.addEventListener('statechange') event.
export function pushState(oldState: State | null, title: string, url: string) {
  // pushState drops any forward history entries
  sessionHistoryOffset = 0
  const newState = {_id: uniqueId(), ...oldState}
  history.pushState(newState, title, url)
  setState(newState)
}

// Indirection for history.replaceState to support tracking URL changes.
//
// Would be great if there was a standard window.addEventListener('statechange') event.
export function replaceState(oldState: Record<string, unknown> | null, title: string, url: string) {
  const newState = {...getState(), ...oldState}
  history.replaceState(newState, title, url)
  setState(newState)
}

// Get URL that be navigated to with history.back().
export function getBackURL(): string | undefined {
  const entry = sessionHistoryEntries[position() - 1]
  if (entry) {
    return entry.url
  }
}

// Get URL that be navigated to with history.forward().
export function getForwardURL(): string | undefined {
  const entry = sessionHistoryEntries[position() + 1]
  if (entry) {
    return entry.url
  }
}

state = initializeState()

window.addEventListener(
  'popstate',
  function onPopstate(event: PopStateEvent) {
    const currentState: State = event.state

    if (!currentState || (!currentState._id && !currentState.turbo?.restorationIdentifier)) {
      // Unmanaged state in history entries
      // Or could be a hashchange pop, ignore and let hashchange handle it
      return
    }

    if (currentState.turbo?.restorationIdentifier) {
      // Each state has a unique restorationIdentifier provided by Turbo. We compare the id
      // to see if we are going backwards or forwards.
      const id = currentState.turbo.restorationIdentifier
      const restoreId = (sessionHistoryEntries[position() - 1]?.state as State)?.turbo?.restorationIdentifier

      if (restoreId === id) {
        sessionHistoryOffset--
      } else {
        sessionHistoryOffset++
      }
    } else {
      // PJAX uses timestamps to know if we are going forward or backward.
      if (currentState._id < (getState()._id || NaN)) {
        sessionHistoryOffset--
      } else {
        sessionHistoryOffset++
      }
    }

    setState(currentState)
  },
  true
)

// Listen turbo visits to reset the `sessionHistoryOffset` in case we are doing a page load
// instead of poping a state from the history stack.
window.addEventListener('turbo:visit', event => {
  if (!(event instanceof CustomEvent)) return
  if (event.detail.action === 'restore') return

  sessionHistoryOffset = 0

  // Add turbo visits to the state stack, so we can keep it complete without `empty` entries.
  replaceState(history.state, '', event.detail.url)
})

window.addEventListener(
  'turbo:load',
  () => {
    // The initial state is set before turbo sets the restorationIdentifier. So we need to
    // replace the initial state with it once Turbo finishes loading.
    replaceState(history.state, '', '')
  },
  {once: true}
)

window.addEventListener(
  'hashchange',
  function onHashchange() {
    if (safeGetHistory() > sessionHistoryEntries.length) {
      // Forward navigation
      const newState = {_id: uniqueId()}
      history.replaceState(newState, '', location.href)
      setState(newState)
    }
  },
  true
)
